import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { api_url } from '../config'; // Update with your URLs

const TokenAdminForm = (props) => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const usernameRef = useRef();
  const expirationRef = useRef();

  // Fetch tokens from the server
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
        const response = await axios.get(`${api_url}/api/listTempTokens`, { headers });
        setTokens(response.data);
      } catch (err) {
        setError('Failed to fetch tokens');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTokens();
  }, [props.token]);

  const revokeToken = async (token) => {
    try {
      const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
      await axios.post(`${api_url}/api/revokeTempToken`, { token }, { headers });
      setTokens((prevTokens) => prevTokens.filter((t) => t.token !== token));
    } catch (err) {
      setError('Failed to revoke token');
      console.error(err);
    }
  };

  const generateToken = async () => {
    const username = usernameRef.current.value.trim();
    const expirationHours = 24 * parseInt(expirationRef.current.value) || 24 * 7;
    const createdBy = (props?.profile?.email)?props.profile.email:'not set';

    if (!username) {
      setError('Username is required');
      return;
    }

    if (expirationHours < 1 || expirationHours > 168) {
      setError('Expiration must be between 1 and 168 hours.');
      return;
    }

    try {
      const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
      const response = await axios.post(
        `${api_url}/api/generateTempToken`,
        { username, expirationHours, createdBy },
        { headers }
      );

      const newToken = response.data.token;
      const newTokenData = {
        token: newToken,
        username,
        createdBy,
        createdAt: new Date(),
        expiration: new Date(Date.now() + expirationHours * 60 * 60 * 1000),
      };

      setTokens((prevTokens) => [...prevTokens, newTokenData]);
      usernameRef.current.value = ''; 
      expirationRef.current.value = '24';
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to generate token');
      console.error(err);
    }
  };

  const copyToClipboard = async (currentUrl, token) => {
    try {
      const separator = currentUrl.includes('?') ? '&' : '?';
      const urlWithToken = `${currentUrl}${separator}token=${token}`;
      await navigator.clipboard.writeText(urlWithToken);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
      alert('Failed to copy the link. Please try again.');
    }
  };

  // Conditional class for dark mode
  const containerClass = props.darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black';
  const modalClass = props.darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black';
  const borderClass = props.darkMode ? 'border-gray-700 ' : 'border-gray-300 bg-gray-100';
  const tableClass = props.darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900';
  const tableHeaderRowClass = props.darkMode ? 'border-gray-700 ' : 'border-gray-400 bg-gray-300';

  const generateButtonClass= props.darkMode ? 'bg-green-800 hover:bg-green-900': 'bg-green-600 hover:bg-green-700';

  return (
    <div className={`w-full p-4 ${containerClass}`}>
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h1 className="text-xl font-bold text-center mb-8">Token Management</h1>

        <button
          className={`flex items-center gap-2 text-white font-bold py-2 px-4 rounded mb-6 ${generateButtonClass}`}
          onClick={() => setIsModalOpen(true)}
        >
          <span className="text-xl">+</span> Generate a New Token
        </button>

        <h1 className="text-xl font-bold mb-4">Active Tokens</h1>
        {tokens.length === 0 ? (
          <p>No temporary tokens issued.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className={`w-full table-auto border-collapse border rounded-lg overflow-hidden ${tableClass}`}>
              <thead>
                <tr className={`${tableHeaderRowClass}`}>
                  <th className={`px-4 py-2 text-left`}>Username</th>
                  <th className={`px-4 py-2 text-left`}>Created By</th>
                  <th className={`px-4 py-2 text-left`}>Created At</th>
                  <th className={`px-4 py-2 text-left`}>Expires At</th>
                  <th className={`px-4 py-2 text-left`}>Link</th>
                  <th className={`px-4 py-2 text-left`}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((token) => (
                  <tr key={token.token}>
                    <td className={`border ${borderClass} px-4 py-2`}>{token.username}</td>
                    <td className={`border ${borderClass} px-4 py-2`}>{token.createdBy}</td>
                    <td className={`border ${borderClass} px-4 py-2`}>
                      {new Date(token.createdAt).toLocaleString()}
                    </td>
                    <td className={`border ${borderClass} px-4 py-2`}>
                      {new Date(token.expiration).toLocaleString()}
                    </td>
                    <td className={`border ${borderClass} px-4 py-2 break-all`}>
                      <button
                        onClick={() => copyToClipboard(token.currentUrl, token.token)}
                        className="text-blue-500 hover:underline"
                      >
                        Link
                      </button>
                    </td>
                    <td className={`border ${borderClass} px-4 py-2`}>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={() => revokeToken(token.token)}
                      >
                        Revoke
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className={`p-6 rounded shadow-lg w-full max-w-lg ${modalClass}`}>
              <h2 className="text-xl font-bold mb-4">Generate New Token</h2>
              <div className="mb-4">
                <label className="block mb-1">Username</label>
                <input
                  ref={usernameRef}
                  type="text"
                  className={`w-full px-4 py-2 border ${borderClass} rounded ${modalClass}`}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Expiration (Days)</label>
                <input
                  ref={expirationRef}
                  type="number"
                  className={`w-full px-4 py-2 border ${borderClass} rounded ${modalClass} `}
                  defaultValue={7}
                />
              </div>
              <div className="flex justify-end gap-4">
                <button className="bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded" onClick={() => setIsModalOpen(false)}>Cancel</button>
                <button className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded" onClick={generateToken}>Generate</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenAdminForm;
