import React, { useRef, useState, useEffect } from 'react';
import { api_url } from "../config";

import JobTable from '../components/jobs/JobTable';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import queryString from 'query-string';
import Spinner from '../components/Spinner';

import { FaSearch, FaTimes } from 'react-icons/fa'; // Importing magnifying glass icon
import SelectDarkMode from '../components/common/SelectDarkMode';

export default function JobFilterPage(props) {

  const location = useLocation();
  const navigate = useNavigate();
  const [locations, setLocations] = useState('');
  const [lcats, setLCATs] = useState('');
  const [contracts, setContracts] = useState('');
  const [jobs, setJobs] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLCATs, setSelectedLCATs] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(true); // Track when jobs are being fetched
  const [jobsRendered, setJobsRendered] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchTextCleared, setSearchTextCleared] = useState(false);

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [parametersParsed, setParametersParsed] = useState(false);

  const[selectedDegree, setSelectedDegree] = useState(null);
  const[selectedYearsExperience, setSelectedYearsExperience] = useState(null);
  const degreeRef = useRef(null);
  const yeRef = useRef(null);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    // alert('parsing query params');
    const parsed = queryString.parse(location.search);
   
    setSearchText(value);
    setIsSearchActive(value !== parsed.searchText); // Enable the button if the searchText is not empty
  };

// Select education level
// Select years experience
function degreeValueChanged(selectedOptions){
    setSelectedDegree(selectedOptions);
    if (selectedOptions === null && yeRef.current) {
         yeRef.current.clearValue()
    }
}

function yearsExperienceValueChanged(selectedOptions){
    setSelectedYearsExperience(selectedOptions);
}

useEffect(() => {
  if ( filtersLoaded){
   handleSearchButtonClick();
   setSearchTextCleared(false);
  }
}, [searchTextCleared])

// Run on filter updates, either new values selected or the jobs list updated
useEffect(() => {
}, [selectedYearsExperience])

  const degrees = [{name: "None", _id: "noDegree"}, {name: "BA/BS", _id: "bachelors"},
    {name: "MA/MS", _id: "masters"}, {name: "PhD", _id: "doctorate"}]

  const yearsExperienceValues = [{name: "None", _id:0},
    {name: "1 Year", _id:1}, {name: "2 Years", _id:2}, {name: "3 Years", _id:3},
    {name: "4 Years", _id:4}, {name: "5 Years", _id:5}, {name: "6 Years", _id:6},
    {name: "7 Years", _id:7}, {name: "8 Years", _id:8}, {name: "9 Years", _id:9},
    {name: "10 Years", _id:10}, {name: "11 Years", _id:11}, {name: "12 Years", _id:12},
    {name: "13 Years", _id:13}, {name: "14 Years", _id:14}, {name: "15 Years", _id:15},
    {name: "16 Years", _id:16}, {name: "17 Years", _id:17}, {name: "18 Years", _id:18},
    {name: "19 Years", _id:19}, {name: "20 Years", _id:20} , {name: "21 Years", _id:21},
    {name: "22 Years", _id:22}, {name: "23 Years", _id:23}, {name: "24 Years+", _id:24} ]
    
  function selectedLocationsChanged(selectedOptions){
    setSelectedLocations(selectedOptions);
    }

    function selectedLCATsChanged(selectedOptions){
        setSelectedLCATs(selectedOptions);
    }
    function selectedContractsChanged(selectedOptions){
        setSelectedContracts(selectedOptions);
    }

    function parseQueryParameters(){
        if (filtersLoaded) {
            // alert('parsing query params');
            const parsed = queryString.parse(location.search);
        
            // Handle search text query param
            if (parsed.searchText) {
              setSearchText(parsed.searchText);
            }
            setIsSearchActive(parsed.searchText === ''); // Enable the button if the searchText is not empty

            if (locations.length > 0 && parsed.locations) {
                const selectedLocations = parsed.locations.split(',').map(value => 
                locations.find(option => option._id === value)
                ).filter(Boolean);
                setSelectedLocations(selectedLocations);
            }
        
            if ( lcats.length > 0 && parsed.lcats) {
                const selectedLaborCategories = parsed.lcats.split(',').map(value => 
                lcats.find(option => option._id === value)
                ).filter(Boolean);
                setSelectedLCATs(selectedLaborCategories);
            }

            if ( contracts.length > 0 && parsed.contracts) {
                const selectedContracts = parsed.contracts.split(',').map(value => 
                contracts.find(option => option._id === value)
                ).filter(Boolean);
                setSelectedContracts(selectedContracts);
            }

            if ( parsed.degree ){
                const selectedDegree = degrees.find(option => option._id === parsed.degree);
                setSelectedDegree(selectedDegree);
            }

            if ( parsed.degree && parsed.yearsExperience ){
                const selYE = yearsExperienceValues.find(option => option._id === Number(parsed.yearsExperience));
                setSelectedYearsExperience(selYE);
            }

            setParametersParsed(true);
        }
    }

    useEffect(() => {
        async function setupDropDownData(){
          try{
          const headers = props.token
          ? { Authorization: `Bearer ${props.token}` }
          : {}; // Include headers only if token exists
          const tempTokenParam = props.tempToken? `?token=${props.tempToken}`:``;
      
            const locationsResponse = await fetch(api_url + '/api/locations' + tempTokenParam,
              {
                headers,
              });
            const laborCategoriesResponse = await fetch(api_url + '/api/lcatTypes' + tempTokenParam,
              {
                headers,
              });
            const contractsResponse = await fetch(api_url + '/api/contracts' + tempTokenParam,
              {
                headers,
              });

              if ( locationsResponse.status === 403 ||
                  laborCategoriesResponse.status === 403 || 
                  contractsResponse.status == 403)
              {
                console.log("Token invalidated");
                props.setToken(null);
              }

            const locationsData = await locationsResponse.json();
            const laborCategoriesData = await laborCategoriesResponse.json();
            const contractsData = await contractsResponse.json();

            setLocations(locationsData.map(location => ({ value: location._id, _id: location._id, name: location.name })));
            setLCATs(laborCategoriesData.map(lcat => ({ value: lcat.type, _id: lcat.type, name: lcat.type })));
            setContracts(contractsData.map(contract => ({ value: contract._id, _id: contract._id, name: contract.name })));
            setFiltersLoaded(true); // Indicate that the filters are loaded
          } 
          catch (exc){
            console.log("Failed to load data: " + exc);
          }
        }

        setupDropDownData();
      }, []);

      useEffect(() => {
        if (jobsRendered) {
          const savedScrollPosition = sessionStorage.getItem("scrollPosition");
          if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
            sessionStorage.removeItem("scrollPosition"); // Clear after restoring
          }
          setJobsRendered(false); // Reset jobs rendered after scroll is restored
        }
      }, [jobsRendered]);

      useEffect(() => {
        if (!loadingJobs && jobs.length > 0) {
          // Wait for the DOM update to finish
          requestAnimationFrame(() => {
            setJobsRendered(true); // Jobs table is now rendered
          });
        }
      }, [loadingJobs, jobs]);


    useEffect(() => {
        parseQueryParameters();
        }, [filtersLoaded, locations, lcats, location.search]);
    

       // Update URL when filters change
    useEffect(() => {
        if ( parametersParsed){
            buildQueryAndFetchJobs();
        }
    }, [parametersParsed, selectedLocations, selectedLCATs, selectedContracts, selectedDegree, selectedYearsExperience, navigate]);

    function buildQueryAndFetchJobs(){
      const selLocations = selectedLocations.map(option => option._id).join(',');
      const selLcats = selectedLCATs.map(option => option._id).join(',');
      const selContracts = selectedContracts.map(option => option._id).join(',');
     
      const query = queryString.stringify({
      locations: selLocations || undefined, // Avoid empty parameters
      lcats: selLcats || undefined,
      contracts: selContracts || undefined,
      degree: selectedDegree ? selectedDegree._id : undefined,
      yearsExperience: selectedYearsExperience ? selectedYearsExperience._id : undefined,
      searchText: searchText || undefined  // Add search text to query
      });

      navigate(`?${query}`, { replace: true });

      const degreeParam = selectedDegree ? '&degree=' + selectedDegree._id : '';
      const yearsExperienceParam = selectedYearsExperience ? '&yearsExperience=' + selectedYearsExperience._id : '';
      
      const headers = props.token
      ? { Authorization: `Bearer ${props.token}` }
      : {}; // Include headers only if token exists
      const tempTokenParam = props.tempToken? `&token=${props.tempToken}`:``;

      fetch(api_url + `/api/jobs?locations=${selectedLocations.map(option => option._id)
        .join(',')}&lcats=${selectedLCATs.map(option => option._id).join(',')}&contracts=${selectedContracts.map(option => option._id)
          .join(',')}${degreeParam}${yearsExperienceParam}&searchText=${searchText}` + tempTokenParam,
      {
        headers,
      })
        .then(response => response.json())
        .then(data => setJobs(data));
    
      setLoadingJobs(false);
    }

    function handleSearchButtonClick() {
      buildQueryAndFetchJobs();
    }

    function csvURL() {
      const degreeParam = selectedDegree ? '&degree=' + selectedDegree._id : '';
      const yearsExperienceParam = selectedYearsExperience ? '&yearsExperience=' + selectedYearsExperience._id : '';
      
      const url = api_url + `/api/jobs/download?locations=${selectedLocations.map(option => option._id)
        .join(',')}&lcats=${selectedLCATs.map(option => option._id).join(',')}&contracts=${selectedContracts.map(option => option._id)
          .join(',')}${degreeParam}${yearsExperienceParam}&searchText=${searchText}`;

          return url;

    }

    async function downloadCSV() {
      const url = csvURL();
      const headers = props.token
        ? { Authorization: `Bearer ${props.token}` }
        : {};
    
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
    
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
    
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
    
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'jobs.csv';
        document.body.appendChild(link);
        link.click();
        link.remove();
    
        // Revoke the URL after the download
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Failed to download CSV:', error);
        alert('Failed to download CSV. Please try again.');
      }
    }

    const containerClass = props.darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
    const buttonClass = props.darkMode
      ? 'bg-gray-800 text-white hover:bg-gray-800'
      : 'bg-gray-300 text-gray-700 hover:bg-gray-200';
    const inputClass = props.darkMode
      ? 'bg-gray-800 border-gray-700 text-white'
      : 'border-gray-300';
      const searchButtonInactiveClass = props.darkMode
      ? 'bg-gray-700'
      : 'bg-gray-300';

  return (
    <div className={`w-full p-4 ${containerClass} ${props.showModal ? "pointer-events-none" : ""}`}>
        <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-1">
            {/* Left side: Search and selects */}
            <div className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                {/* Search bar with clear button */}
                <div className="relative flex items-center mb-0 lg:mb-0">
              <input
                type="text"
                value={searchText}
                onChange={handleSearchInputChange}
                placeholder="Text Search..."
                className={`w-full border rounded-md p-2 focus:outline-none ${inputClass}`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && isSearchActive) {
                    handleSearchButtonClick();
                  }
                }}
              />
              {searchText && (
                <button
                  onClick={() => {
                    const parsed = queryString.parse(location.search);
                    if (parsed.searchText !== '') {
                      setSearchText("");
                      setSearchTextCleared(true);
                    } else {
                      handleSearchInputChange({ target: { value: '' } });
                    }
                  }}
                  className={`absolute right-10 p-1 focus:outline-none p-2 rounded ${buttonClass}`}
                
                >
                  <FaTimes className="text-gray-500" />
                </button>
              )}
              <button
                onClick={handleSearchButtonClick}
                disabled={!isSearchActive}
                className={`ml-2 p-2 rounded-md ${isSearchActive ? 'bg-blue-400' : searchButtonInactiveClass + ' cursor-not-allowed'}`}
              >
                <FaSearch className="text-white" />
              </button>
          </div>

            <div>
              <div className="flex flex-col md:flex-row md:space-x-4">
      
                 <SelectDarkMode setRef={degreeRef} optionsValues={degrees} value={selectedDegree}  isMulti={false} onChange={(selected) => {
                    degreeValueChanged(selected);
                    if (!selected) {
                      setSelectedYearsExperience(null); // Clear years of experience if degree is cleared
                    }
                  }} placeholder={"Degree/Years Exp"} darkMode={props.darkMode} />
           

                <SelectDarkMode setRef={yeRef} optionsValues={yearsExperienceValues} 
                  value={selectedYearsExperience} onChange={yearsExperienceValueChanged} isDisabled={selectedDegree === null} isMulti={false} darkMode={props.darkMode}/>
              </div>
            </div>
          </div>

          {/* LCAT and Contracts selects */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">

            {/* Location dropdown */}
            <SelectDarkMode optionsValues={locations} value={selectedLocations} onChange={selectedLocationsChanged} isMulti={true} placeholder={"All Locations"} darkMode={props.darkMode} />
            <SelectDarkMode optionsValues={lcats} value={selectedLCATs} onChange={selectedLCATsChanged} isMulti={true} placeholder={"All LCATs"} darkMode={props.darkMode} />
            <SelectDarkMode optionsValues={contracts} value={selectedContracts} onChange={selectedContractsChanged} isMulti={true} placeholder={"All Contracts"} darkMode={props.darkMode} />

          </div>
        </div>
      </div>
    </div>
  
    <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
      
      {loadingJobs ? <Spinner/> : jobs.length > 0 ? (
        <div className="mt-2 md:mt-2 lg:mt-4 text-center">
          <JobTable jobs={jobs} darkMode={props.darkMode} />
          <p className="text-center text-gray-500 mt-6 mb-4">
            Matching job req count - {jobs.length}
          </p>
          {props.userIsAdmin && (
            <button
              className="text-gray-500 underline hover:text-gray-700"
              onClick={downloadCSV}
            >
              Download as CSV
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center my-8">
          <p className="text-center text-gray-500 mb-4">
            No matching job reqs found
          </p>
          <button
            className="text-blue-500 hover:underline"
            onClick={() => {
              setSelectedLocations([]);
              setSelectedLCATs([]);
              setSelectedContracts([]);
              setSelectedDegree(null);
              setSelectedYearsExperience(null);
              setSearchText("");
            }}
          >
            Clear all filters
          </button>
        </div>
      )}
    </div>
  </div>
  );
};
