import { useState } from 'react';

export default function Settings({ darkMode, toggleDarkMode, profile }) {

  // Set dynamic styles for light or dark mode
  const containerClass = darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900';

  const mainLevelClass = darkMode ? 'hover:text-blue-200 text-blue-300': 'hover:text-blue-500 text-blue-600';

  return (
    
    <div className={`w-full p-4`}>
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto ">
        <h1 className="text-xl font-bold text-center mb-8">Settings</h1>

        <div className={`p-6 max-w-lg mx-auto rounded shadow-md ${containerClass}`}>
    
          {profile ? (
                <div>
                <h3>Profile Info</h3>
                <p><strong>Name:</strong> {profile.name}</p>
                <p><strong>Email:</strong> {profile.email}</p>
                <p><strong>Groups:</strong> {profile.groups.join(', ')}</p>
                <p>
                <strong>Role:</strong> {profile.isAdmin ? 'Administrator' : 'User'}
                </p>
                </div>
          ) : (
            <p>No profile information available.</p>
          )}

          <hr className="my-4" />

          <div className="flex items-center justify-between">
            <label htmlFor="dark-mode-toggle" className="text-lg">
              Dark Mode
            </label>
            <input
              id="dark-mode-toggle"
              type="checkbox"
              checked={darkMode}
              onChange={toggleDarkMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
