import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api_url } from '../config';
import { setWithExpiry } from '../api/storageAccess';
import Spinner from './Spinner';

const Callback = ({ setToken, setProfile }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthorizationCode = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');

      if (code) {
        try {
          // Send the authorization code to the backend and receive token + profile
          const response = await axios.post(api_url + '/api/auth/callback', { code });
          const { token, profile } = response.data;

          // Store token and profile in localStorage with expiry
          setToken(token);
          setWithExpiry('token', token);
          setWithExpiry('profile', profile);
          setProfile(profile);

          // Get the redirect path from localStorage, fallback to home
          const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
          localStorage.removeItem('redirectAfterLogin');

          // Navigate to the stored path or the home page
          navigate(redirectPath);
        } catch (error) {
          alert('Failed to exchange code for token - ' + error);
          console.error('Failed to exchange code for token', error);
        }
      }
    };

    getAuthorizationCode();
  }, [navigate, setToken, setProfile]);

  return <div><Spinner /></div>;
};

export default Callback;
