import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { UsersIcon } from '@heroicons/react/20/solid'
import { UserCircleIcon } from '@heroicons/react/20/solid'
import logo from '../resources/logo_only.png'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import { api_url } from '../config';
import TokenGenerateButton from './TokenGenerateButton'


const navigation = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
]

const navigationLoggedInAll = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
]

const navigationLoggedInAdmin = [
  { name: 'Kontana Jobs', href: '/jobs', current: false },
  { name: 'Import', href: '/imports', current: false },
  { name: 'Data', href: '/edit', current: false },
  { name: 'Tokens', href: '/tokens', current: false },
]

const smallNavigationProfile = [
  { name: 'Settings', href: '/settings', current: false },
  { name: 'Login', href: '/login', current: true }
]

const smallNavigationProfileLoggedIn = [
  { name: 'Settings', href: '/settings', current: false },
  { name: 'Logout', href: '/logout', current: true }
]

 function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
 }

function navigationItems(token, userIsAdmin){
  if ( token && userIsAdmin ){
    return navigationLoggedInAdmin;
  } else if (token) {
    return navigationLoggedInAll;
  } else{
    return navigation;
  }
}


function smallNavigationProfileItems(token){
  if ( token ){
    return smallNavigationProfileLoggedIn;
  }else{
    return smallNavigationProfile;
  }
}

function renderUserIcon(token, profile){
  // Show the user icon with email if logged in, else show generic users icon
  if (token && profile && profile.email) {
    return (
      <div className="flex items-center">
        <UserCircleIcon className="mr-2 h-8 w-8 text-gray-400" aria-hidden="true" />
        <span className="text-sm font-medium text-white">{profile.email}</span>
      </div>
    );
  } else {
    return (
      <UsersIcon className="mr-1.5 h-8 w-8 text-gray-400" aria-hidden="true" />
    );
  }
}

function renderMenuItems(token, darkMode) {
  const dropDownClass = darkMode
? 'border-gray-300 bg-gray-700 text-white placeholder-gray-400'
: 'border-gray-400 bg-white text-gray-900 placeholder-gray-500'


  return (
    <Menu.Items className={`absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${dropDownClass}`}>
      {/* Settings Option - Visible Always */}
      <Menu.Item className={dropDownClass}>
        {({ active }) => (
          <NavLink
            to="/settings"
            className={classNames(
              active ? 'bg-gray-100' : '',
              'block px-4 py-2 text-sm text-gray-700'
            )}
          >
            Settings
          </NavLink>
        )}
      </Menu.Item>

      {token ? (
        <Menu.Item className={dropDownClass}>
          {({ active }) => (
            <a
              href="/logout"
              className={classNames(
                active ? 'bg-red-800' : 'bg-red-900',
                'block px-4 py-2 text-sm text-gray-100'
              )}
            >
              Log out
            </a>
          )}
        </Menu.Item>
      ) : (
        <Menu.Item className={dropDownClass}>
          {({ active }) => (
            <a
              href="/login"
              className={classNames(
                active ? 'bg-gray-200' : '',
                'block px-4 py-2 text-sm text-gray-700'
              )}
            >
              Log in
            </a>
          )}
        </Menu.Item>
      )}
    </Menu.Items>
  );
}

 export default function NavigationBar(props) {

  const [loadingToken, setLoadingToken] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState('');
  const [expirationDays, setExpirationDays] = useState(7);
  
  // Conditional class for dark mode
  const modalClass = props.darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black';
  const borderClass = props.darkMode ? 'border-gray-700' : 'border-gray-300 bg-gray-100';
  const displayLinkClass = props.darkMode ? 'text-gray-200' : 'text-gray-900';

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Shareable link copied to clipboard!');
  };
  
  const generateTempLink = async () => {
    try {
      setLoadingToken(true);
      const currentUrl = window.location.href;
      const headers = props.token ? { Authorization: `Bearer ${props.token}` } : {};
      const expirationDaysConverted = (expirationDays *24);
      const createdBy = (props?.profile?.email)?props.profile.email:'not set';
      const usernameConverted = username? username: 'No name specified';
      const response = await axios.post(
        `${api_url}/api/generateTempToken`,
        { username:usernameConverted, expirationHours:expirationDaysConverted , currentUrl, createdBy },
        { headers }
      );
  
      const { token } = response.data;
      
      // Determine if the current URL already has a '?' or not
      const separator = currentUrl.includes('?') ? '&' : '?';

      // Construct the new URL with the token parameter
      const url = `${currentUrl}${separator}token=${token}`;
      copyToClipboard(url);
      setUsername("");
      setExpirationDays(7);
      props.setShowModal(false);
    } catch (error) {
      console.error('Error generating temporary token:', error);
      alert('Failed to generate shareable link');
    } finally {
      setLoadingToken(false);
    }
  };

   const truncateUrl = (url) => {
      if (url.length <= 50) return url; // If URL is less than or equal to 50 characters, return it as-is.
      const start = url.slice(0, 22); // First 22 characters.
      const end = url.slice(-22); // Last 22 characters.
      return `${start}........${end}`; // Combine with "........" in the middle.
    };
  
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className={`mx-auto max-w-full px-2 sm:px-6 lg:px-8`}>
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" ><img className="block h-8 w-auto lg:block"
                    src={logo}
                    alt="Kontana"/></a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    
                    {navigationItems(props.token, props.userIsAdmin).map((item) => (
                      <NavLink key={item.name} 
                            className={({ isActive, isPending }) => classNames(
                              isPending ? "bg-gray-900 text-white" : isActive ? 
                              "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white ", 
                              " rounded-md px-3 py-2 text-sm font-medium")}
                              to={item.href}>
                              {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
       
        {props.profile && 
        (window.location.href.includes('job')) && 
        <TokenGenerateButton profile={props.profile} setModal={props.setShowModal} modal={props.showModal} loadingToken={loadingToken} />}
              {/* Modal */}
              {props.showModal && (
                <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className={`p-6 rounded z-50 shadow-lg w-full max-w-lg ${modalClass}`}>
                  <h2 className="text-xl font-bold mb-4">Create Shareable Link</h2>
                  <div className={`mb-4 ${displayLinkClass}`}>
                    <label className="block mb-1 ">Link URL: {truncateUrl(window.location.href)}</label>
                   </div>
                  <div className="mb-4">
                    <label className="block mb-1">Created For:</label>
                      <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        placeholder='Email or Name of Person You Are Sending Jobs To'
                        className={`w-full px-4 py-2 border ${borderClass} rounded ${modalClass}`}
                      />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-1">Expiration (Days):</label>
                    <input
                      type="number"
                      className={`w-full px-4 py-2 border ${borderClass} rounded ${modalClass} `}
                      defaultValue={7}
                      value={expirationDays}
                      onChange={(e) => setExpirationDays(Number(e.target.value))}
                    />
                  </div>
  
                    {/* Buttons */}
                    <div className="flex justify-end gap-4">
                      <button className="bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded" onClick={() => props.setShowModal(false)}>Cancel</button>
                      <button className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded" onClick={generateTempLink}>Generate</button>
                    </div>
                  </div>
                </div>
              )}

              <div className="invisible sm:visible absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                {/* Profile dropdown */}
                <Menu as="div" className={`relative ml-3`}>
                  <div>
                    <Menu.Button 
                      disabled={props.showModal} 
                      className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      {renderUserIcon(props.token, props.profile)}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {renderMenuItems(props.token, props.darkMode)}
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigationItems(props.token, props.userIsAdmin).map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

            {smallNavigationProfileItems(props.token,props.profile).map((item) => (
              <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={classNames(
                  item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )}
                aria-current={item.current ? 'page' : undefined}>
              {item.name}
            </Disclosure.Button>
            ))}
            </div>
  
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
