import Select from 'react-select';

export default function SelectDarkMode ({optionsValues, value, onChange, isDisabled, isMulti, placeholder, darkMode, setRef}) {
    return (
        <Select
        options={optionsValues}
        setRef={setRef}
        isMulti={isMulti}
        getOptionLabel={(option) => `${option['name']}`}
        getOptionValue={(option) => `${option['_id']}`}
        isSearchable={false}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={true}
        onChange={onChange}
        className={`border rounded-md flex-1 ${
          darkMode
            ? 'border-gray-600 bg-gray-800 text-white placeholder-gray-400'
            : 'border-gray-400 bg-white text-gray-900 placeholder-gray-500'
        }`}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: darkMode ? '#1f2937' : 'white', // Adjust control background
            borderColor: darkMode ? '#4b5563' : '#d1d5db', // Adjust border color
            boxShadow: 'none',
            '&:hover': {
              borderColor: darkMode ? '#a1a1a1' : '#d1d5db', // Border color on hover
            },
          }),
          singleValue: (provided) => ({
            ...provided,
            color: darkMode ? 'white' : 'black',
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: darkMode ? '#4b5563' : '#e2e8f0',
            color: darkMode ? 'white' : 'black',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: darkMode ? 'white' : 'black',
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: darkMode ? 'white' : 'black',
            '&:hover': {
              backgroundColor: darkMode ? '#3b3f43' : '#f7fafc',
              color: darkMode ? 'white' : 'black',
            },
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: darkMode ? '#1f2937' : 'white',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? (darkMode ? '#374151' : '#edf2f7') : (darkMode ? '#1f2937' : 'white'),
            color: darkMode ? 'white' : 'black',
          }),
        }}
      />
    );
}