import React, { useEffect } from 'react';

export default function LoginRequired(props) {
  const { darkMode } = props;

  useEffect(() => {
    // Store the current path before redirecting to login
    const currentPath = window.location.pathname;
    localStorage.setItem('redirectAfterLogin', currentPath);
  }, []);

  // Conditional styles based on dark mode
  const containerStyle = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const textStyle = darkMode ? 'text-indigo-400' : 'text-indigo-600';
  const buttonStyle = darkMode 
    ? 'bg-indigo-700 hover:bg-indigo-600 text-white' 
    : 'bg-indigo-600 hover:bg-indigo-500 text-white';

  return (
    <main className={`grid min-h-full place-items-top py-24 px-6 sm:py-32 lg:px-8 ${containerStyle}`}>
      <div className="text-center">
        <p className={`text-base font-semibold ${textStyle}`}>403</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
          Login Required
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
          You are required to login to view this page
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/login"
            className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${buttonStyle}`}
          >
            Login
          </a>
        </div>
      </div>
    </main>
  );
}
