import React, { useState } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const ImportContractForm = ({ token, contract, setDialogContract, profile, darkMode }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const createdBy = (profile?.email)?profile.email:'not set';

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      const message = 'Please select a file.';
      setUploadStatus(message);
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('createdBy', createdBy);

    try {
      const response = await axios.post(
        `${api_url}/api/admin/${contract}/process`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let { data } = response;
      let message = '';

      if (data.error) message = data.error;
      else if (data.message) message = data.message + '\r\n';

      if (data.stats) {
        if (data.stats.jobsAdded) message += `Added ${data.stats.jobsAdded}\r\n`;
        if (data.stats.jobsDeleted) message += `Deleted ${data.stats.jobsDeleted}\r\n`;
        if (data.stats.jobsUpdated) message += `Updated ${data.stats.jobsUpdated}\r\n`;
        if (data.stats.jobsUnchanged) message += `Unmodified ${data.stats.jobsUnchanged}\r\n`;
      }

      alert(message);
      setDialogContract(null); // Close dialog after success
    } catch (error) {
      alert(`Error processing file: ${error.message}`);
    }
  };

  const handleClose = () => setDialogContract(null); // Close dialog

  const containerClass = darkMode ? 'bg-gray-800 text-white' : 'bg-white';
  const inputClass = darkMode
    ? 'border-gray-600 bg-gray-700 text-white focus:ring-indigo-400'
    : 'border-gray-300 bg-white focus:ring-indigo-500';
  const buttonClass = darkMode
    ? 'bg-indigo-500 hover:bg-indigo-400'
    : 'bg-indigo-600 hover:bg-indigo-700';
  const textClass = darkMode ? 'text-gray-300' : 'text-gray-700';

  return (
    <div
      className={`relative max-w-xl mx-auto mt-8 p-6 rounded-lg shadow-md ${containerClass}`}
    >
      {/* Close Button */}
      <button
        onClick={handleClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-400 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h1 className="text-xl font-semibold text-center mb-4">
        {contract.toUpperCase()} Job Import
      </h1>

      <form onSubmit={handleFileUpload} className="space-y-6">
        <div>
          <label htmlFor="fileInput" className={`block text-sm font-medium ${textClass}`}>
            Select File
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            accept=".csv,.xlsx,.xls"
            className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 sm:text-sm ${inputClass}`}
          />
        </div>

        <button
          type="submit"
          className={`w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-opacity-50 ${buttonClass}`}
        >
          Process File
        </button>
      </form>

      {uploadStatus && (
        <div className="mt-4 text-center">
          <p
            className={`text-sm font-semibold ${
              uploadStatus.includes('successfully') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {uploadStatus}
          </p>
        </div>
      )}
    </div>
  );
};

export default ImportContractForm;
