import React from 'react';

export default function Unauthorized(props) {

  const containerStyle = props.darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const textStyle = props.darkMode ? 'text-indigo-400' : 'text-indigo-600';
  const buttonStyle = props.darkMode 
    ? 'bg-indigo-700 hover:bg-indigo-600 text-white' 
    : 'bg-indigo-600 hover:bg-indigo-500 text-white';
    
    return (
      <>
        <main className={`grid min-h-full place-items-top py-24 px-6 sm:py-32 lg:px-8 ${containerStyle}`}>
          <div className="text-center">
            <p className={`text-base font-semibold ${textStyle}`}>403</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unauthorized</h1>
            <p className={`text-base font-semibold ${textStyle}`}>You are not authorized to view this page</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/login"
                className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${buttonStyle}`}
              >
                Return to Home
              </a>
            </div>
          </div>
        </main>
      </>
    )
  }
  
