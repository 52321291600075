import JobRow from './JobRow';
import { getOpenJobsOnly } from '../../util/filters';

export default function JobTable(props) {
  const containerClass = props.darkMode 
    ? 'bg-gray-800 shadow-md sm:rounded-md' 
    : 'bg-white shadow sm:rounded-md';
  const dividerClass = props.darkMode 
    ? 'divide-gray-700' 
    : 'divide-gray-200';
  const emptyMessageClass = props.darkMode 
    ? 'text-white' 
    : 'text-gray-900';

  return (
    <div className={`overflow-hidden ${containerClass}`}>
      <ul className={`divide-y ${dividerClass}`}>
        {props.jobs && getOpenJobsOnly(props.jobs).map((job) => (
          <JobRow 
            key={job._id} 
            job={job} 
            state={props.state} 
            darkMode={props.darkMode} 
          />
        ))}
        
        {props.jobs.length === 0 && (
          <li className={`px-6 py-4 whitespace-nowrap ${emptyMessageClass}`}>
            No matching jobs found
          </li>
        )}
      </ul>
    </div>
  );
}
