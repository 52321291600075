import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const LocationList = ({ token, darkMode }) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    axios
      .get(api_url + '/api/locations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setLocations(res.data))
      .catch((err) => console.error(err));
  }, [token]);

   // Handle location delete
   const handleDeleteLocation = async (id, name) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the location : ${name}?`);
    if (!confirmDelete) {
      return; // Do nothing if the user cancels
    }
    try {
      await axios.delete(`${api_url}/api/location/${id}`, {
        headers: {
          Authorization: `Bearer ${token}` // Pass the token here
        }
      });

      setLocations(locations.filter(location => location._id !== id));
    } catch (error) {
      if ( error?.response?.data?.error){
        alert(error.response.data.error)
      }else{
        console.error("Error deleting location:", error);
      }
    }
   };

   const containerClass = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
   const buttonClass = darkMode
     ? 'bg-gray-700 text-white hover:bg-gray-600'
     : 'bg-gray-300 text-gray-700 hover:bg-gray-200';
   const inputClass = darkMode
     ? 'bg-gray-800 border-gray-700 text-white'
     : 'border-gray-300';

  return (
    <div className="container mx-auto p-6">
      <ul className="space-y-4">
        {locations.map((loc) => (
          <li
            key={loc._id}
            className={`p-4 rounded-lg shadow-md flex justify-between items-center ${
              darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
            } transition`}
          >
            <div>
              <h4 className="text-lg font-semibold">{loc.name}</h4>
            </div>

            <div className="space-x-3">
              <button
                onClick={() => handleDeleteLocation(loc._id, loc.name)}
                className="text-sm text-red-500 font-medium hover:text-red-700"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationList;
