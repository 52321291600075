import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { api_url } from '../../config';
import Spinner from '../Spinner';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

export default function JobDetail(props) {
  const { uuid } = useParams();
  const [job, setJob] = useState(props.job || null);
  const [loaded, setLoaded] = useState(!!props.job);
  const [error, setError] = useState(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('URL copied to clipboard!');
  };

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to top on load
    if (!props.job) {
      const tempTokenParam = props.tempToken ? `?token=${props.tempToken}` : ``;
      const fetchJob = async () => {
        try {
          const headers = props.token
            ? { Authorization: `Bearer ${props.token}` }
            : {}; // Include headers only if token exists

          const response = await fetch(`${api_url}/api/job/${uuid}` + tempTokenParam, { headers });
          if (response.ok) {
            const jobData = await response.json();
            setJob(jobData);
          } else if (response.status === 403) {
            console.log("Token Invalid");
            props.setToken(null);
          } else {
            setError("Job does not exist or not found");
          }
        } catch (err) {
          setError("An error occurred while fetching the job");
        } finally {
          setLoaded(true);
        }
      };
      fetchJob();
    }
  }, [uuid, props.job]);

  if (!loaded) {
    return <div><Spinner /></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const containerClass = props.darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const spanClass = props.darkMode ? 'text-gray-100' : 'text-gray-600'
  const detailClass = props.darkMode 
    ? 'bg-gray-800 border-gray-700' 
    : 'bg-gray-100 border-gray-300';
    const tableClass = props.darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900';
    const buttonClass = props.darkMode ? 'bg-white text-gray-900': 'bg-gray-800 text-white';
    const headerClass = props.darkMode ? 'bg-gray-700' : 'bg-gray-100';

  return (
    <div className={`w-full p-4 ${containerClass}`}>
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">

        {/* Job Details */}
        <div className={`p-6 border rounded-b-md ${detailClass}`}>
          <h1 className="text-2xl font-bold">{job.lcat.description ? job.lcat.description : job.lcat.name}</h1>

          <div className="mt-2 flex items-center space-x-4">
            <span className={spanClass}>{job.name}</span>
            <button
              className={`flex items-center space-x-2 px-2 py-1 text-sm rounded ${buttonClass}`}
              onClick={() => copyToClipboard(window.location.href)}
            >
              <ClipboardIcon className="h-4 w-4" />
              <span >Copy URL</span>
            </button>
          </div>

          <div className="mt-4">
            <h3 className="font-semibold">Contract / TTO</h3>
            <p className={spanClass}>{job.contract.name} / {job.tto}</p>
          </div>

          {(job.subTask !== undefined && job.subTask !== "") && (
            <div className="mt-4">
              <h3 className="font-semibold">Sub Task</h3>
              <p className={spanClass}>{job.subTask}</p>
            </div>
          )}

          {job.fte !== 1 && (
            <div className="mt-4">
              <h3 className="font-semibold">FTE</h3>
              <p className={spanClass}>{job.fte}</p>
            </div>
          )}

          <div className="mt-4">
            <h3 className="font-semibold">Location</h3>
            <p className={spanClass}>{job.location.name}</p>
          </div>

          <div className="mt-4">
            <h3 className="font-semibold">Telework</h3>
            <p className={spanClass}>{job.teleworkAvailable}</p>
          </div>

          {job.description !== "" && (
            <div className="mt-4">
              <h3 className="font-semibold">Description</h3>
              <div className={spanClass}>
                <ReactMarkdown
                  remarkPlugins={[remarkBreaks]}
                  components={{
                    ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
                    ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
                    li: ({ children }) => <li className="mb-2">{children}</li>,
                  }}
                >
                  {job.description}
                </ReactMarkdown>
              </div>
            </div>
          )}

          {job.requiredSkills !== "" && (
            <div className="mt-4">
              <h3 className="font-semibold">Required Skills</h3>
              <div className={spanClass}>
                <ReactMarkdown
                  remarkPlugins={[remarkBreaks]}
                  components={{
                    ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
                    ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
                    li: ({ children }) => <li className="mb-2">{children}</li>,
                  }}
                >
                  {job.requiredSkills}
                </ReactMarkdown>
              </div>
            </div>
          )}

          {job.desiredSkills !== "" && (
            <div className="mt-4">
              <h3 className="font-semibold">Desired Skills</h3>
              <div className={spanClass}>
                <ReactMarkdown
                  remarkPlugins={[remarkBreaks]}
                  components={{
                    ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
                    ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
                    li: ({ children }) => <li className="mb-2">{children}</li>,
                  }}
                >
                  {job.desiredSkills}
                </ReactMarkdown>
              </div>
            </div>
          )}

          <div className="mt-4">
            <h3 className="font-semibold">Years Experience Required</h3>
            <table className={`table-auto w-full mt-2 border-collapse border ${tableClass}`}>
              <thead className={headerClass}>
                <tr >
                  <th className="border border-gray-300 px-2 py-1">No Degree</th>
                  <th className="border border-gray-300 px-2 py-1">BA/BS</th>
                  <th className="border border-gray-300 px-2 py-1">MA/MS</th>
                  <th className="border border-gray-300 px-2 py-1">PhD</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.noDegree >= 0 ? job.lcat.noDegree : "N/A"}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.bachelors >= 0 ? job.lcat.bachelors : "0"}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.masters >= 0 ? job.lcat.masters : job.lcat.bachelors >= 0 ? job.lcat.bachelors : "0"}</td>
                  <td className="border border-gray-300 px-2 py-1">{job.lcat.doctorate >= 0 ? job.lcat.doctorate : job.lcat.masters >= 0 ? job.lcat.masters : "0"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
