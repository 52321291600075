import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const LCATList = ({ contractId, token, darkMode }) => {
  const [lcats, setLCATs] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [newLCAT, setNewLCAT] = useState({
    name: '',
    description: '',
    noDegree: 0,
    bachelors: 0,
    masters: 0,
    doctorate: 0,
  });
  const [editingLCAT, setEditingLCAT] = useState(null);

  useEffect(() => {
    axios
      .get(api_url + `/api/lcats/${contractId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setLCATs(res.data))
      .catch((err) => console.error(err));
  }, [contractId, token]);

  const handleEditLCAT = (lcat) => {
    setEditingLCAT(lcat);
    setNewLCAT({ ...lcat });
    setShowEditDialog(true);
  };

  const handleUpdateLCAT = async () => {
    try {
      const response = await axios.put(
        `${api_url}/api/lcat/${editingLCAT._id}`,
        newLCAT,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLCATs((lcats) =>
        lcats.map((l) => (l._id === editingLCAT._id ? response.data : l))
      );
      setShowEditDialog(false);
      setEditingLCAT(null);
      setNewLCAT({
        name: '',
        description: '',
        noDegree: 0,
        bachelors: 0,
        masters: 0,
        doctorate: 0,
      });
    } catch (error) {
      console.error('Error updating LCAT:', error);
    }
  };

  const containerClass = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const buttonClass = darkMode
    ? 'bg-gray-700 text-white hover:bg-gray-600'
    : 'bg-gray-300 text-gray-700 hover:bg-gray-200';
  const inputClass = darkMode
    ? 'bg-gray-800 border-gray-700 text-white'
    : 'border-gray-300';

  return (
    <div>
      {/* Edit LCAT Dialog */}
      {showEditDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
          <div
            className={`${containerClass} p-6 rounded w-2/3 max-h-screen overflow-y-auto`}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold mb-4">Edit LCAT</h3>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newLCAT.name}
              onChange={(e) => setNewLCAT({ ...newLCAT, name: e.target.value })}
              placeholder="Enter LCAT name"
            />
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newLCAT.description}
              onChange={(e) =>
                setNewLCAT({ ...newLCAT, description: e.target.value })
              }
              placeholder="Enter LCAT description"
            />
            {['noDegree', 'bachelors', 'masters', 'doctorate'].map((field) => (
              <div key={field} className="mb-4">
                <label className="block mb-1 capitalize">{field} Experience</label>
                <input
                  type="number"
                  className={`border rounded w-full p-2 ${inputClass}`}
                  value={newLCAT[field]}
                  onChange={(e) =>
                    setNewLCAT({ ...newLCAT, [field]: e.target.value })
                  }
                />
              </div>
            ))}
            <div className="flex justify-end">
              <button
                className={`${buttonClass} px-4 py-2 rounded mr-2`}
                onClick={() => {
                  setShowEditDialog(false);
                  setNewLCAT({
                    name: '',
                    description: '',
                    noDegree: 0,
                    bachelors: 0,
                    masters: 0,
                    doctorate: 0,
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                onClick={handleUpdateLCAT}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* LCAT List */}
      <ul className="space-y-4">
        {lcats.length <= 0 ? "No LCATs Found":lcats.map((lcat) => (
          <li
            key={lcat._id}
            className={`p-4 rounded-lg shadow-md flex justify-between items-center ${
              darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
            } transition`}
          >
            <div>
              <h4 className="text-lg font-semibold">{lcat.name}</h4>
              <p className="text-sm">{lcat.description}</p>
            </div>
            <button
              onClick={() => handleEditLCAT(lcat)}
              className="text-sm text-blue-500 font-medium hover:text-blue-700"
            >
              Edit
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LCATList;
