import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api_url } from '../config';

const JobList = ({ contractId, token, darkMode }) => {
  const [jobs, setJobs] = useState([]);
  const [lcats, setLCATs] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [filteredLCATs, setFilteredLCATs] = useState([]); // Filtered LCATs based on contract
  const [newJob, setNewJob] = useState({
    name: '',
    description: '',
    subTask: '',
    fte: 0,
    clearanceLevel: 'TS/SCI with FSP',
    teleworkAvailable: '',
    contract: '',
    lcat: '',
    tto: '',
    location: '',
    status: '',
    desiredSkills: '',
    requiredSkills: ''
  });
  const [editingJob, setEditingJob] = useState(null);

  useEffect(() => {
    if (editingJob) {
      // Filter LCATs based on the job's contract or selected contract
      const contractLCATs = lcats.filter(lcat => lcat.contract?._id === newJob.contract);
      setFilteredLCATs(contractLCATs);
  
      // alert('cat-' + newJob.lcat);
      // Ensure the LCAT value stays even if the contract is changed
      if (!contractLCATs.some(lcat => lcat._id === newJob.lcat)) {
        setNewJob(prevState => ({ ...prevState, lcat: '' })); // Clear LCAT if it doesn't belong to the selected contract
      }
    }
  }, [newJob.contract, editingJob]);

  useEffect(() => {
    // fetchJobs();
    fetchLCATs();
    fetchContracts();
    fetchLocations();
  }, []);

  // Fetch LCATs
  const fetchLCATs = async () => {
    try {
      const response = await axios.get(`${api_url}/api/lcats`,
        {
          headers: {
            Authorization: `Bearer ${token}` // Pass the token here
          }
        });
      setLCATs(response.data);
    } catch (error) {
      console.error('Error fetching LCATs:', error);
    }
  };

  // Fetch Contracts
  const fetchContracts = async () => {
    try {
      const response = await axios.get(`${api_url}/api/contracts`,
        {
          headers: {
            Authorization: `Bearer ${token}` // Pass the token here
          }
        });
      setContracts(response.data);
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };

  // Fetch Locations
  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${api_url}/api/locations`,
        {
          headers: {
            Authorization: `Bearer ${token}` // Pass the token here
          }
        });
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  useEffect(() => {
    axios
      .get(api_url + `/api/jobs?contracts=${contractId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setJobs(res.data))
      .catch((err) => console.error(err));
  }, [contractId, token]);

  // Handle job delete
  const handleDeleteJob = async (id, name) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the job : ${name}?`);
    if (!confirmDelete) {
      return; // Do nothing if the user cancels
    }
    try {
      await axios.delete(`${api_url}/api/job/${id}`, {
        headers: {
          Authorization: `Bearer ${token}` // Pass the token here
        }
      });
      setJobs(jobs.filter(job => job._id !== id));
    } catch (error) {
      if ( error?.response?.data?.error){
        alert(error.response.data.error)
      }else{
        console.error("Error deleting job:", error);
      }
    }
  };

  // Handle job update
  const handleUpdateJob = async () => {
    try {
      const response = await axios.put(`${api_url}/api/job/${editingJob._id}`, newJob, {
        headers: {
          Authorization: `Bearer ${token}` // Pass the token here
        }
      });
      setJobs(jobs.map(job => (job._id === editingJob._id ? response.data : job)));
      setShowEditDialog(false);
      setEditingJob(null);
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

   // Handle job edit button
   const handleEditJob = (job) => {
    setEditingJob(job);
    setNewJob({
      name: job.name,
      description: job.description|| '',
      clearanceLevel: job.clearanceLevel|| '',
      teleworkAvailable: job.teleworkAvailable|| '',
      contract: job.contract?._id || '',
      lcat: job.lcat?._id || '',
      tto: job.tto|| '',
      fte: job.fte || 1,
      location: job.location?._id || '',
      status: job.status,
      subTask: job.subTask|| '',
      desiredSkills: job.desiredSkills || '',
      requiredSkills: job.requiredSkills || ''
    });
    setShowEditDialog(true);
  };

    // Check if there are changes to enable the "Update" button
const isJobChanged = () => {
  
  let changed = (
    newJob.name !== editingJob.name ||
    newJob.description !== editingJob.description ||
    newJob.clearanceLevel !== editingJob.clearanceLevel ||
    ( ((newJob.contract !== undefined || editingJob.contract !== undefined) && !newJob.contract === editingJob.contract ) && newJob.contract._id !== editingJob.contract._id ) ||
    ( ((newJob.lcat !== undefined || editingJob.lcat !== undefined) && !newJob.lcat === editingJob.lcat ) && newJob.lcat._id !== editingJob.lcat._id ) ||
    newJob.tto !== editingJob.tto ||
    newJob.subTask !== editingJob.subTask ||
    newJob.fte !== editingJob.fte ||
    newJob.teleworkAvailable !== editingJob.teleworkAvailable ||
    ( ((newJob.location !== undefined || editingJob.location !== undefined) && !newJob.location === editingJob.location ) && newJob.location._id !== editingJob.location._id ) ||
    newJob.status !== editingJob.status ||
    newJob.requiredSkills !== editingJob.requiredSkills ||
    newJob.desiredSkills !== editingJob.desiredSkills
  );

  return changed;
};

const buttonUpdate = () =>{
  {/* Update Button */}
 return <button
  className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 ${!isJobChanged() && 'opacity-50 cursor-not-allowed'}`}
  onClick={handleUpdateJob}
  disabled={!isJobChanged()} // Disable button if no changes are detected
>
  Update
</button>
};

  const containerClass = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const buttonClass = darkMode
    ? 'bg-gray-700 text-white hover:bg-gray-600'
    : 'bg-gray-300 text-gray-700 hover:bg-gray-200';
  const inputClass = darkMode
    ? 'bg-gray-800 border-gray-700 text-white'
    : 'border-gray-300';

  return (
    <div>
        {showEditDialog && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75"
         // onClick={() => setShowEditDialog(false)} // Close modal when clicking outside
        >
          {/* Modal Content */}
          <div
            class={`${containerClass} p-6 rounded w-2/3 max-h-screen overflow-y-auto`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <h3 className="text-lg font-semibold mb-4">
              {editingJob ? 'Edit Job' : 'Add New Job'}
            </h3>

            <label className="block">Job Name</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.name}
              onChange={(e) => setNewJob({ ...newJob, name: e.target.value })}
              placeholder="Job Name"
            />

            <label className="block">Description</label>
            <textarea
              className={`border rounded p-2 mb-4 h-24 w-full p-2 ${inputClass}`}
              value={newJob.description}
              onChange={(e) => setNewJob({ ...newJob, description: e.target.value })}
              placeholder="Job Description"
            />

            <label className="block">Clearance Level</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.clearanceLevel}
              onChange={(e) => setNewJob({ ...newJob, clearanceLevel: e.target.value })}
              placeholder="Clearance Level"
            />

            <label className="block">Telework Available</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.teleworkAvailable}
              onChange={(e) => setNewJob({ ...newJob, teleworkAvailable: e.target.value })}
              placeholder="Telework Available"
            />

          {/* Contract Dropdown */}
          <label className="block">Contract</label>
          <select
            className={`border rounded w-full p-2 mb-4 ${inputClass}`}
            value={newJob.contract}
            disabled={true}
          >
            <option value="">Select Contract</option>
            {contracts.map(contract => (
              <option key={contract._id} value={contract._id}>
                {contract.name}
              </option>
            ))}
          </select>

        {/* LCAT Dropdown */}
        <label className="block">LCAT</label>
          <select
            className={`border rounded w-full p-2 mb-4 ${inputClass}`}
            value={newJob.lcat}
            onChange={(e) => setNewJob({ ...newJob, lcat: e.target.value })}
          >
            <option value="">Select LCAT</option>
            {filteredLCATs.map(lcat => (
              <option key={lcat._id} value={lcat._id}>
                {lcat.name}
              </option>
            ))}
          </select>

            <label className="block">TTO</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.tto}
              onChange={(e) => setNewJob({ ...newJob, tto: e.target.value })}
              placeholder="TTO"
            />

            <label className="block">SubTask</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.subTask}
              onChange={(e) => setNewJob({ ...newJob, subTask: e.target.value })}
              placeholder="SubTask"
            />

            <label className="block">Location</label>
            <select
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.location}
              onChange={(e) => setNewJob({ ...newJob, location: e.target.value })}
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location._id} value={location._id}>
                  {location.name}
                </option>
              ))}
            </select>

            <label className="block">Status</label>
            <input
              type="text"
              className={`border rounded w-full p-2 mb-4 ${inputClass}`}
              value={newJob.status}
              onChange={(e) => setNewJob({ ...newJob, status: e.target.value })}
              placeholder="Status"
            />

            <label className="block">Required Skills</label>
            <textarea
              className={`border rounded p-2 mb-4 h-24 w-full p-2 ${inputClass}`}
              value={newJob.requiredSkills}
              onChange={(e) => setNewJob({ ...newJob, requiredSkills: e.target.value })}
              placeholder="Required Skills"
            />

            <label className="block">Desired Skills</label>
            <textarea
              className={`border rounded p-2 mb-4 h-24 w-full p-2 ${inputClass}`}
              value={newJob.desiredSkills}
              onChange={(e) => setNewJob({ ...newJob, desiredSkills: e.target.value })}
              placeholder="Desired Skills"
            />

            <div className="flex justify-end">
              <button
                className={`${buttonClass} px-4 py-2 rounded mr-2`}
                onClick={() => setShowEditDialog(false)}
              >
                Cancel
              </button>
              { buttonUpdate() }
            </div>
          </div>
        </div>
      )}
      <ul className="space-y-4">
        {jobs.length <= 0 ? "No Jobs Found":jobs.map((job) => (
          <li
            key={job._id}
            className={`p-4 rounded-lg shadow-md flex justify-between items-center ${
              darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
            } transition`}
          >
            <div>
              <h4 className="text-lg font-semibold">{job.name}</h4>
            </div>

            <div className="space-x-3">
              <button
                className="text-sm text-blue-500 font-medium hover:text-blue-700"
                onClick={() => handleEditJob(job)}
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteJob(job._id, job.name)}
                className="text-sm text-red-500 font-medium hover:text-red-700"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobList;
