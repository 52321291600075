import React from 'react';
import WordCloudComponent from '../components/WordCloudComponent';

export default function WordCloudPage(props) {

  // Conditional class for dark mode
  const containerClass = props.darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black';
  
  return (
    <div className={`w-screen h-screen ${containerClass} flex items-center justify-center`}>
      {/* <div className="w-full lg:w-2/3 items-center"> */}
        {/* <h1 className="text-xl font-bold text-center mb-8">Summary</h1> */}
        <div className='w-screen h-screen'>
          <WordCloudComponent token={props.token} />
        {/* </div> */}
      </div>
    </div>
  );
}
