import { LinkIcon } from '@heroicons/react/20/solid'; // Icons from Heroicons

const TokenGenerateButton = ({ profile, modal, setModal, loadingToken }) => {
  if (!profile) return null; // Render nothing if not an admin

  const handleClick = () => {
    modal ? setModal(false) : setModal(true);
  };

  const buttonText = loadingToken
    ? "Creating..."
    : "Create a Shareable Link";


  const Icon = LinkIcon; 

  return (
    <button
      className="flex items-center space-x-2 bg-blue-600 p-1 text-white rounded-full hover:bg-blue-700 transition"
      onClick={handleClick}
      disabled={loadingToken}
      title={buttonText} // Mouseover text
    >
      <Icon className="h-5 w-5" />
    </button>
  );
};

export default TokenGenerateButton;