import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import Root from './components/Root';
import * as serviceWorker from './serviceWorker';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render( <BrowserRouter>
    <Root />
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
