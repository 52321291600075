import { MapPinIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function JobRow(props) {
    const navigate = useNavigate(); // Initialize useNavigate

    function rowClickHandler() {
        handleRowClick(props.job._id);
    }

    function handleRowClick(jobId) {
        // Save the scroll position in session storage
        sessionStorage.setItem("scrollPosition", window.scrollY);
        // Navigate to the job detail page
        navigate(`/job/${jobId}`);
    }

    function allowsRemoteText(teleworkAvailable) {
        return teleworkAvailable === undefined || teleworkAvailable === "" || teleworkAvailable === "No"
            ? ""
            : `/Telework - ${teleworkAvailable}`;
    }

    // Conditional classes for dark mode
    const containerClass = props.darkMode 
        ? 'bg-gray-800 text-white hover:bg-gray-700' 
        : 'bg-gray-100 text-gray-900 hover:bg-gray-200';
    const headerTextClass = props.darkMode 
        ? 'text-white hover:text-blue-300' 
        : 'text-gray-900 hover:text-blue-400';
    const locationTextClass = props.darkMode 
        ? 'text-gray-300 hover:text-blue-300' 
        : 'text-gray-500 hover:text-blue-400';
    const badgeClass = props.darkMode 
        ? 'bg-gradient-to-r from-gray-700 to-gray-600 text-gray-100' 
        : 'bg-gradient-to-r from-blue-100 to-blue-200 text-gray-800';

    return (
        <li key={props.job._id}>
            <div
                onClick={rowClickHandler}
                className={`block p-4 transition-all duration-300 rounded-lg cursor-pointer ${containerClass}`}
            >
                {/* Header: Title and Description */}
                <div className="flex flex-col sm:flex-row justify-between mb-2">
                <p 
                    className={`text-base font-normal truncate transition-colors duration-200 text-left ${headerTextClass}`}
                >
                        {props.job.lcat.description || props.job.lcat.name} - {props.job.name}
                        {props.job.fte > 1 && ` (${props.job.fte} FTEs)`}
                    </p>
                </div>

                {/* Location and Badge Section */}
                <div className="flex flex-wrap items-center justify-between text-sm">
                    {/* Location */}
                    <div className="flex items-center mb-2 sm:mb-0">
                        <MapPinIcon className="h-5 w-5 text-blue-400 mr-1.5" aria-hidden="true" />
                        <span className={`transition-colors duration-200 ${locationTextClass}`}>
                            {props.job.location.name}{allowsRemoteText(props.job.teleworkAvailable)}
                        </span>
                    </div>

                    {/* Badge */}
                    <div className="ml-auto">
                        <p className={`inline-flex items-center rounded-full px-3 py-1 text-xs font-medium ${badgeClass}`}>
                            {props.job.tto} - {props.job.contract.id}
                        </p>
                    </div>
                </div>
            </div>
        </li>
    );
}
