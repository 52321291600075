import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { api_url } from '../config';
import JobList from './JobList';
import LCATList from './LCATList';
import LocationList from './LocationList';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const EditPage = (props) => {
  const { darkMode, token } = props;
  const [contracts, setContracts] = useState([]);
  const [contractsExpanded, setContractsExpanded] = useState(false);
  const [expandedContracts, setExpandedContracts] = useState({});
  const [locationsExpanded, setLocationsExpanded] = useState(false);

  useEffect(() => {
    axios
      .get(api_url + '/api/contracts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setContracts(res.data))
      .catch((err) => console.error(err));
  }, [token]);

  const toggleLocationsExpand = () => {
    setLocationsExpanded((prev) => !prev);
  };

  const toggleContractsExpand = () => {
    setContractsExpanded((prev) => !prev);
  };

  const toggleContractExpand = (contractId) => {
    setExpandedContracts((prevState) => ({
      ...prevState,
      [contractId]: !prevState[contractId],
    }));
  };

  // Set dynamic styles for light or dark mode
  const containerClass = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const sectionClass = darkMode ? 'bg-gray-800' : 'bg-gray-100';
  const hoverClass = darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50';

  const mainLevelClass = darkMode ? 'hover:text-blue-200 text-blue-300': 'hover:text-blue-500 text-blue-600';

  return (
    <div className={`w-full p-4 ${containerClass}`}>
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h1 className="text-xl font-bold text-center mb-8">Data Management</h1>

        {/* Contracts Section */}
        <div className={`${sectionClass} p-6 rounded-lg shadow-md`}>
          <div
            className={`flex justify-between items-center cursor-pointer ${hoverClass} p-3 rounded`}
            onClick={toggleContractsExpand}
          >
            <h2 className={`text-xl font-semibold ${mainLevelClass}`}>Contracts</h2>
            {contractsExpanded ? (
              <ChevronDownIcon className="w-6 h-6 text-gray-400" />
            ) : (
              <ChevronRightIcon className="w-6 h-6 text-gray-400" />
            )}
          </div>
          {contractsExpanded && (
            <ul className="space-y-3 mt-4 ml-4 border-l-2 border-gray-200 pl-4">
              {contracts.map((contract) => (
                <li key={contract.id} className="pb-2">
                  <div
                    className={`flex justify-between items-center cursor-pointer ${hoverClass} p-3 rounded`}
                    onClick={() => toggleContractExpand(contract.id)}
                  >
                    <span className="text-lg font-semibold">{contract.name}</span>
                    {expandedContracts[contract.id] ? (
                      <ChevronDownIcon className="w-6 h-6 text-gray-400" />
                    ) : (
                      <ChevronRightIcon className="w-6 h-6 text-gray-400" />
                    )}
                  </div>

                  {expandedContracts[contract.id] && (
                    <div className="mt-4 ml-6 space-y-6">
                      <div>
                        <h3 className="text-lg font-semibold">Jobs</h3>
                        <JobList contractId={contract._id} token={token} darkMode={props.darkMode} />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold">LCATs</h3>
                        <LCATList contractId={contract._id} token={token} darkMode={props.darkMode}/>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Locations Section */}
        <div className={`${sectionClass} p-6 rounded-lg shadow-md mb-8`}>
          <div
            className={`flex justify-between items-center cursor-pointer ${hoverClass} p-3 rounded`}
            onClick={toggleLocationsExpand}
          >
            <h2 className={`text-xl font-semibold ${mainLevelClass}`}>Locations</h2>
            {locationsExpanded ? (
              <ChevronDownIcon className="w-6 h-6 text-gray-400" />
            ) : (
              <ChevronRightIcon className="w-6 h-6 text-gray-400" />
            )}
          </div>
          {locationsExpanded && (
            <div className="mt-4 ml-6">
              <LocationList token={token} darkMode={props.darkMode}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPage;
