import React, { useEffect, useState } from 'react';
import WordCloud from 'react-d3-cloud';
import { api_url } from '../config';

const WordCloudComponent = ({ token }) => {
    const [words, setWords] = useState([]);
    const [dimensions, setDimensions] = useState({ width: 2400, height: 1600 });


    useEffect(() => {
        async function fetchData() {
            const response = await fetch(api_url + '/api/words', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            
            // console.log(data);
            setWords(data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const updateDimensions = () => {
            const width = window.innerWidth; // Cap width at 600px
            const height = window.innerHeight; // Cap height at 400px

            console.log(width + " " + height)
            setDimensions({ width, height });
        };
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const fontSizeMapper = word => Math.log2(word.value) * 100;

    return (
<div className="items-center justify-center">
    <WordCloud
        data={words}
        height={dimensions.height}
        width={dimensions.width}
        padding={5}
        fontSizeMapper={fontSizeMapper} 
    />
      {/* <WordCloud
      data={words}
      width={500}
      height={500}
      font="Times"
      fontStyle="italic"
      fontWeight="bold"
      fontSize={fontSize}
      spiral="rectangular"
      rotate={rotate}
      padding={5}
      random={Math.random}
      fill={fill}
      onWordClick={onWordClick}
      onWordMouseOver={onWordMouseOver}
      onWordMouseOut={onWordMouseOut}
    /> */}
</div>
    );
};

export default WordCloudComponent;
