import React, { useState, useEffect } from "react";
import ImportIDForm from './ImportIDForm';
import axios from "axios";
import { FaCheckCircle, FaSync, FaUpload } from "react-icons/fa";
import dayjs from "dayjs";
import { api_url } from "../config";
import Spinner from "../components/Spinner";
import ImportContractForm from "./ImportContractForm";

// Helper: Calculate the difference in weeks
const getWeeksDiff = (date) => {
  if (!date) return -1;
  return Math.floor(dayjs().diff(dayjs(date), "week"));
};

// Helper: Determine status color
const getStatusColor = (weeks) => {
  if (weeks === -1) return "text-gray-400";
  if (weeks <= 1) return "text-green-400";
  if (weeks <= 2) return "text-yellow-400";
  return "text-red-400";
};

const ImportDashboard = ({ token, profile, darkMode }) => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogContract, setDialogContract] = useState(null);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const [contractsRes, importsRes] = await Promise.all([
          axios.get(`${api_url}/api/contracts`, { headers }),
          axios.get(`${api_url}/api/imports`, { headers }),
        ]);

        const mappedSources = contractsRes.data.map((contract) => {
          const lastImport = importsRes.data
            .filter((imp) => imp.contract._id === contract._id)
            .sort((a, b) => new Date(b.created) - new Date(a.created))[0];

          return {
            id: contract._id,
            name: contract.name,
            abbrev: contract.id,
            lastChecked: contract.lastUpdated,
            lastImport: lastImport ? lastImport.created : null,
            jobsAdded: lastImport?.added || 0,
            jobsDeleted: lastImport?.deleted || 0,
            jobsUpdated: lastImport?.updated || 0,
            jobsUnchanged: lastImport?.unchanged || 0,
          };
        });

        setSources(mappedSources);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to load sources.");
      } finally {
        setLoading(false);
      }
    };

    fetchSources();
  }, [token, dialogContract]);

  const handleCheckSource = async (id) => {
    const confirmed = window.confirm("Click Ok to manually reset the contract status");
    if (!confirmed) return;

    try {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      await axios.put(`${api_url}/api/contract/${id}`, { lastUpdated: new Date() }, { headers });
      setSources((prev) =>
        prev.map((src) => (src.id === id ? { ...src, lastChecked: new Date() } : src))
      );
    } catch (error) {
      console.error("Error checking source:", error);
      alert("Failed to update the check date.");
    }
  };

  const handleImportData = (contract) => {
    setDialogContract(contract.name);
  };

  const renderDialogForm = () => {
    switch (dialogContract) {
      case 'KINGSROAD':
        return <ImportContractForm token={token} contract="kr" setDialogContract={setDialogContract} profile={profile} darkMode={darkMode} />;
      case 'IMAGINEDRAGON':
        return <ImportIDForm token={token} setDialogContract={setDialogContract} profile={profile} darkMode={darkMode} />;
      case 'TITLEBELT':
        return <ImportContractForm token={token} contract="tb" setDialogContract={setDialogContract} profile={profile} darkMode={darkMode}/>;
      case 'TECHNICALKNOCKOUT':
        return <ImportContractForm token={token} contract="tko" setDialogContract={setDialogContract} profile={profile} darkMode={darkMode} />;
      case 'THEPROGRAM2':
        return <ImportContractForm token={token} contract="tp2" setDialogContract={setDialogContract} profile={profile} darkMode={darkMode} />;
      default:
        return null;
    }
  };

  if (loading) return <Spinner />;

  const containerClass = darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900';
  const tableClass = darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900';
  const headerClass = darkMode ? 'bg-gray-700' : 'bg-gray-300';
  const hoverRowClass = darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50';
  const buttonClass = darkMode
    ? 'bg-gray-700 text-white hover:bg-gray-600'
    : 'bg-gray-200 hover:bg-gray-300';

  return (
    <div className={`w-full p-4 ${containerClass}`}>
      <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
        <h1 className="text-xl font-bold text-center mb-8">Contact Import Status</h1>
        <div className="overflow-x-auto">
        <table className={`w-full table-auto border-collapse border rounded-lg overflow-hidden ${tableClass}`}>
          <thead>
            <tr className={headerClass}>
              <th className="border px-4 py-2 text-left">Contract</th>
              <th className="border px-4 py-2 text-left">Last Check</th>
              <th className="border px-4 py-2 text-left">Last Update</th>
              <th className="border px-4 py-2 text-center">Status</th>
              <th className="border px-4 py-2 text-center">Import</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((source) => {
              const weeksSinceChecked = getWeeksDiff(source.lastChecked);
              const weeksSinceUpdated = getWeeksDiff(source.lastImport);

              return (
                <tr key={source.id} className={`border ${hoverRowClass}`}>
                  <td className="px-4 py-2">{source.name || source.abbrev}</td>
                  <td className="px-4 py-2">
                    {source.lastChecked ? dayjs(source.lastChecked).format('YYYY-MM-DD HH:mm') : 'Never Checked'}
                    <button onClick={() => handleCheckSource(source.id)} className="ml-2 p-1">
                      <FaSync className="text-gray-600 hover:text-blue-400" />
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    {source.lastImport ? dayjs(source.lastImport).format('YYYY-MM-DD HH:mm') : 'Never Updated'}
                    <div className="text-xs text-gray-500">
                      {source.jobsAdded} new, {source.jobsDeleted} deleted, {source.jobsUpdated} updated, {source.jobsUnchanged} unmodified
                    </div>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <div className="flex items-center justify-center h-full">
                      <FaCheckCircle
                        className={`text-xl ${getStatusColor(
                          Math.min(weeksSinceChecked, weeksSinceUpdated)
                        )}`}
                      />
                    </div>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button onClick={() => handleImportData(source)} className={`p-2 rounded ${buttonClass}`}>
                      <FaUpload />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
        {dialogContract && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-75">
            {renderDialogForm()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportDashboard;
