import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { api_url } from '../config';

Modal.setAppElement('#root'); // Accessibility

const ImportIDForm = ({ token, setDialogContract, profile, darkMode }) => {
  const [files, setFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const createdBy = (profile?.email)?profile.email:'not set';

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${api_url}/api/admin/id/files`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredDocFiles = response.data.files.filter((file) =>
        file.fileName.endsWith('.docx')
      );
      setDocumentFiles(filteredDocFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileChange = (e) => setFiles(e.target.files);

  const handleUpload = async () => {
    if (!files.length) return alert('Please select a file.');

    const hasCsv = Array.from(files).some((file) =>
      file.name.endsWith('.csv') || file.name.endsWith('.xlsx')
    );

    if (hasCsv) {
      await processCsvFile(files[0]); // Assume only one CSV file allowed
    } else {
      await uploadDocumentFiles(files);
    }

    setFiles([]); // Clear selected files
    fetchFiles();
  };

  const processCsvFile = async (csvFile) => {
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('createdBy', createdBy);

    try {
      const response = await axios.post(`${api_url}/api/admin/id/process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      displayResults(response);
    } catch (error) {
      alert(`Error processing CSV: ${error.message}`);
    }
  };

  const uploadDocumentFiles = async (files) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append('files', file));
    formData.append('createdBy', createdBy);
    
    try {
      const response = await axios.post(`${api_url}/api/admin/id/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      displayResults(response);
    } catch (error) {
      alert(`Error uploading documents: ${error.message}`);
    }
  };

  const displayResults = (response) => {
    let message = response.data.message || 'CSV processed successfully.';

    if (response.data.stats) {
      const { jobsAdded, jobsDeleted, jobsUpdated, jobsUnchanged } = response.data.stats;
      message += jobsAdded ? `\nAdded: ${jobsAdded}` : '';
      message += jobsDeleted ? `\nDeleted: ${jobsDeleted}` : '';
      message += jobsUpdated ? `\nUpdated: ${jobsUpdated}` : '';
      message += jobsUnchanged ? `\nUnmodified: ${jobsUnchanged}` : '';
    }

    const errors = response.data.errors || '';
    message += `\r\n${errors}`;
    alert(message);
    setDialogContract(null); // Close the dialog
  };

  const handleClose = () => setDialogContract(null);

  // Conditional Styling based on dark mode
  const containerClass = darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900';
  const modalHeaderClass = darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700';
  const inputClass = darkMode
    ? 'border-gray-600 bg-gray-700 text-white focus:ring-indigo-400'
    : 'border-gray-300 bg-white focus:ring-indigo-500';
  const buttonClass = darkMode
    ? 'bg-indigo-500 hover:bg-indigo-400'
    : 'bg-indigo-600 hover:bg-indigo-700';
  const modalBgClass = darkMode ? 'bg-gray-900' : 'bg-white';

  return (
    <div className={`relative max-w-xl mx-auto mt-8 p-6 rounded-lg shadow-md ${containerClass}`}>
      <button
        onClick={handleClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-400 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h1 className="text-xl font-semibold text-center mb-4">ID Job Import</h1>

      <div className="mb-6">
        <label className="block text-sm font-medium">
          Upload Files (CSV, DOCX, ZIP)
        </label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          accept=".csv,.xlsx,.xls,.docx,.zip"
          className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm ${inputClass}`}
        />
        <button
          onClick={handleUpload}
          className={`mt-4 w-full py-2 px-4 rounded ${buttonClass} text-white font-bold`}
        >
          Upload / Process
        </button>
      </div>

      <button
        className="mt-6 px-4 py-2 bg-gray-600 text-white font-semibold rounded-md hover:bg-gray-700"
        onClick={() => setShowModal(true)}
      >
        List Uploaded ID Documents
      </button>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className={`p-8 rounded-lg shadow-lg w-full max-w-lg ${modalHeaderClass}`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className={`text-lg font-semibold mb-4 ${modalHeaderClass}`}>Uploaded .docx Files</h2>
        <ul className={`list-disc pl-5 max-h-64 overflow-y-auto ${modalHeaderClass} `}>
          {documentFiles.map((file, idx) => (
            <li key={idx}>
              {file.fileName} (Uploaded on: {new Date(file.uploadDate).toLocaleString()})
            </li>
          ))}
        </ul>
        <button
          className="mt-6 px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default ImportIDForm;
